<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <b-card no-body>
          <b-card-header>
            <i class="icon-list"></i>
            Orçamento
            <b-button
              v-if="canPrint && show"
              v-on:click="printOrcamento"
              size="sm"
              class="float-right"
              variant="primary"
            >
              <i class="fa fa-print"></i> Imprimir Proposta
            </b-button>
            <b-button
              v-if="!disabled"
              v-on:click="duplicarOrcamento"
              size="sm"
              class="float-right mr-3"
              variant="primary"
            >
              <i class="fa fa-copy"></i> Duplicar Orçamento
            </b-button>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col md="2">
                <b-form-group label="Código" label-for="id">
                  <b-form-input
                    id="id"
                    type="text"
                    :value="orcamento.id"
                    placeholder="Código"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="1">
                <b-form-group
                  v-if="clientStatus != null"
                  label="Status"
                  label-for="status"
                >
                  <h3>
                    <orcamento-status id="status" :status="orcamentoStatus" />
                  </h3>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Data de Emissão" label-for="data_emissao">
                  <b-form-input
                    id="data_emissao"
                    type="text"
                    :value="orcamento.data_emissao | formatDate"
                    placeholder="Data de Emissão"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Data de Validade"
                  label-for="data_validade"
                >
                  <b-form-input
                    id="data_validade"
                    type="text"
                    :value="orcamento.data_validade | formatDate"
                    placeholder="Data de Validade"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Previsão de Fechamento"
                  label-for="previsao_fechamento"
                >
                  <b-form-input
                    id="previsao_fechamento"
                    :type="'date'"
                    :value="orcamento.previsao_fechamento"
                    placeholder="Previsão de Fechamento"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Contato" label-for="contato">
                  <b-form-input
                    id="contato"
                    type="text"
                    v-model="orcamento.contato"
                    placeholder="Contato"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2">
                <b-form-group label="CNPJ" label-for="cnpj">
                  <b-form-input
                    id="cnpj"
                    type="text"
                    :value="clientBasicInfo.cnpj | cnpj"
                    placeholder="CNPJ"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="8">
                <b-form-group label="Razão Social" label-for="razao_social">
                  <b-form-input
                    id="razao_social"
                    type="text"
                    :value="clientBasicInfo.razao_social"
                    placeholder="Razão Social"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  v-if="clientStatus != null"
                  label="Status do Cliente"
                  label-for="status"
                >
                  <h3>
                    <cliente-status id="status" :status="clientStatus" />
                  </h3>
                </b-form-group>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col>
                <b-card no-body>
                  <b-card-header class="p-2">
                    <i class="icon-list ml-1"></i>
                    <span
                      ><b>{{ getFilial() }}</b></span
                    >
                  </b-card-header>
                  <b-card-body class="orcamento-table-card-body font-12">
                    <b-table
                      class="w-100 orcamento-footer mb-0"
                      :items="orcamentoItens"
                      :fields="fields"
                      v-on:sort-changed="onSortChanged"
                      striped
                      small
                      hover
                      bordered
                      :responsive="true"
                      :sort-by.sync="sortBy"
                      :sort-direction.sync="sortDirection"
                      :foot-clone="true"
                      :no-footer-sorting="true"
                      :no-sort-reset="true"
                      stacked="sm"
                      ref="cartTable"
                    >
                      <template #thead-top="">
                        <b-tr>
                          <b-th class="text-center" colspan="2">
                            Informações
                          </b-th>
                          <b-th
                            class="text-center"
                            variant="default"
                            colspan="8"
                          >
                            Valores
                          </b-th>
                        </b-tr>
                      </template>
                      <template v-if="isEditing" v-slot:cell(id)="data">
                        <div class="btn-flex">
                          <b-button
                            class="my-data-table-row-button"
                            size="sm"
                            variant="primary"
                            v-on:click="editBudgetItem(data.item)"
                          >
                            <i class="fa fa-edit"></i>
                          </b-button>
                          <b-button
                            class="my-data-table-row-button"
                            size="sm"
                            variant="danger"
                            v-on:click="confirmDeleteItem(data.item)"
                          >
                            <i class="fa fa-trash"></i>
                          </b-button>
                        </div>
                      </template>
                      <template v-slot:cell(titulo)="data">
                        <div class="float-left">
                          {{ data.item.titulo }}
                        </div>
                        <orcamento-item-alerta
                          :key="data.item.id"
                          v-if="canEdit"
                          class="float-right"
                          :orcamentoId="data.item.orcamento_id"
                          :orcamentoItemId="data.item.id"
                        ></orcamento-item-alerta>
                      </template>
                      <template v-slot:cell(valor_unitario)="data">
                        {{ data.item.valor_unitario | money }}
                      </template>
                      <template v-slot:cell(margem_valor)="data">
                        {{ data.item.margem_valor | money }}
                      </template>
                      <template v-slot:cell(valor_ipi)="data">
                        {{
                          (data.item.valor_ipi + data.item.valor_margem_ipi)
                            | money
                        }}
                      </template>
                      <template v-slot:cell(valor_st)="data">
                        {{
                          (data.item.valor_st + data.item.valor_margem_st)
                            | money
                        }}
                      </template>
                      <template v-slot:cell(valor_bruto_unit)="data">
                        {{
                          (data.item.valor_unitario +
                            data.item.margem_valor +
                            (data.item.valor_ipi + data.item.valor_margem_ipi) +
                            (data.item.valor_st + data.item.valor_margem_st))
                            | money
                        }}
                      </template>
                      <template v-slot:cell(valor_total)="data">
                        {{ data.item.valor_total | money }}
                      </template>
                      <template v-slot:cell(valor_comissao)="data">
                        {{ data.item.valor_comissao | money }}
                      </template>
                      <template v-slot:foot(titulo)>
                        <div class="w-100" id="info-frete">
                          Para compras no valor acima de R$ 2.000,00, o frete é
                          pago pela Dicomp, com exceção para racks e cabos.<br />
                          Para compras de racks e cabos, o frete será por conta
                          do destinatário.
                        </div>
                      </template>
                      <template v-slot:foot()>
                        <span class="text-white">-</span>
                      </template>
                      <template v-slot:foot(margem_valor)>Totais:</template>
                      <template v-slot:foot(valor_total)>
                        <div class="w-100 text-right moeda-real">
                          {{ valorTotalSum | money }}
                        </div>
                      </template>
                      <template v-slot:foot(valor_comissao)>
                        <div class="w-100 text-right moeda-real">
                          {{ comissaoSum | money }}
                        </div>
                      </template>
                    </b-table>
                    <div class="d-block w-100 mt-3 d-sm-none">
                      <div class="w-100 border d-flex pt-2 pb-2">
                        <div
                          class="float-left w-40 text-right font-weight-bold pr-2 pl-2"
                        >
                          Valor Total
                        </div>
                        <div class="float-left w-60">
                          <div class="w-100 text-right moeda-real pr-2 pl-2">
                            {{ valorTotalSum | money }}
                          </div>
                        </div>
                      </div>
                      <div class="w-100 border d-flex pt-2 pb-2">
                        <div
                          class="float-left w-40 text-right font-weight-bold pr-2 pl-2"
                        >
                          Comissão Total
                        </div>
                        <div class="float-left w-60">
                          <div class="w-100 text-right moeda-real pr-2 pl-2">
                            {{ comissaoSum | money }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
            <forma-pgto
              :key="exibirPagamentoSelecionado"
              :status-orcamento="formaPagamentoEditing"
              :forma-pagamento="this.orcamento.forma_pgto"
              :cond-pagamento="this.orcamento.cond_pgto"
              :obs="this.orcamento.observacao"
            >
            </forma-pgto>
            <hr />
            <b-row>
              <b-col md="2">
                <b-button
                  v-if="canEdit && !disabled"
                  v-on:click="confirmSend"
                  :disabled="!canSend"
                  variant="primary"
                  class="w-100 mb-2"
                >
                  <i class="fa fa-send"></i> Enviar Pedido
                </b-button>
              </b-col>
              <b-col md="8"></b-col>
              <b-col md="2">
                <b-button
                  v-if="canEdit && !disabled"
                  v-on:click="editOrcamento"
                  class="w-100 mb-2"
                  variant="success"
                >
                  <i class="fa fa-edit"></i> Editar
                </b-button>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col md="12">
                <div class="w-100" id="info-vlMinimo">
                  Para enviar o pedido, o valor mínimo deve ser de R$ 600,00,
                  considerando a Quantidade, o Valor unitário dos produtos e a
                  Margem.
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<style>
.orcamento-table-card-body {
  overflow: scroll;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 0 !important;
}
.orcamento-footer tfoot,
.orcamento-footer tfoot > tr,
.orcamento-footer tfoot > tr > th {
  border: none;
}
.orcamento-footer tfoot > tr > th:nth-child(12),
.orcamento-footer tfoot > tr > th:nth-child(13) {
  border: 1px solid #c8ced3;
}
.orcamento-footer tfoot > tr > th:nth-child(11),
.orcamento-footer tfoot > tr > th:nth-child(12),
.orcamento-footer tfoot > tr > th:nth-child(13) {
  text-align: right;
}
/* .orcamento-table-card-body tbody tr:nth-of-type(odd) .table-success{
  background-color: #cdedd8;
}
.orcamento-table-card-body tbody tr:nth-of-type(even) .table-success{
  background-color: rgba(205, 237, 216, 0.6);
}
.orcamento-table-card-body tbody tr:nth-of-type(odd) .table-info{
  background-color: #d3eef6;
}
.orcamento-table-card-body tbody tr:nth-of-type(even) .table-info{
  background-color: rgba(211, 238, 246, 0.6);
} */
.orcamento-table-card-body tfoot tr th {
  background-color: white;
}
.orcamento-table-card-body tfoot tr:last-child th:hover {
  background-color: white;
}

.btn-flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#info-frete {
  color: red;
  font-weight: 500;
}

#info-vlMinimo {
  color: blue;
  font-weight: 600;
}

.div-observacao {
  margin-top: -1%;
  margin-bottom: 1%;
}
</style>

<script>
import Orcamento from "../../../services/orcamento";
import Cliente from "../../../services/cliente";
import ClienteStatus from "../clientes/ClienteStatus";
import OrcamentoStatus from "./OrcamentoStatus";
import OrcamentoItemAlerta from "./OrcamentoItemAlerta";
import { EventBus } from "../../../helpers/eventBus";
import OrcamentoEditar from "./OrcamentoEditar.vue";
import GrupoPermissao from "../../../services/grupoPermissao";
import FormaPgto from "../FormaPgto";

export default {
  name: "Orcamento",
  components: {
    ClienteStatus,
    OrcamentoStatus,
    OrcamentoItemAlerta,
    FormaPgto,
  },
  data: () => ({
    hasProductChanges: false,
    valida: false,
    show: false,
    productChangesReturned: 0,
    sortBy: "produto_codigo",
    sortDirection: "asc",
    orcamento: new Object(),
    orcamentoItens: [],
    currentOrcamentoItem: new Object(),
    orcamento_id: null,
    clientBasicInfo: new Object(),
    filial: "",
    fields: [
      {
        key: "produto_codigo",
        label: "Cód.",
        sortable: true,
        tdClass: "text-right",
        thClass: "text-center",
      },
      { key: "titulo", label: "Descrição", sortable: true },
      {
        key: "quantidade",
        label: "Qtde.",
        sortable: true,
        tdClass: "text-right",
        thClass: "text-center",
      },
      {
        key: "valor_unitario",
        label: "Vl.Unit. S/ ipi",
        sortable: true,
        tdClass: "text-right moeda-real-div ",
        thClass: "text-center ",
      },
      {
        key: "margem_valor",
        label: "Margem Unit.",
        sortable: true,
        tdClass: "text-right moeda-real-div ",
        thClass: "text-center ",
      },
      {
        key: "valor_ipi",
        label: "Valor Ipi Unit.",
        sortable: true,
        tdClass: "text-right moeda-real-div ",
        thClass: "text-center ",
      },
      {
        key: "valor_st",
        label: "Valor St Unit.",
        sortable: true,
        tdClass: "text-right moeda-real-div ",
        thClass: "text-center ",
      },
      {
        key: "valor_bruto_unit",
        label: "Valor Venda Unitário",
        sortable: true,
        tdClass: "text-right moeda-real-div ",
        thClass: "text-center ",
      },
      {
        key: "valor_total",
        label: "Valor Venda Total",
        sortable: true,
        tdClass: "text-right moeda-real-div ",
        thClass: "text-center ",
      },
      {
        key: "valor_comissao",
        label: "Prêmio",
        sortable: true,
        tdClass: "text-right moeda-real-div",
        thClass: "text-center",
      },
    ],
    disabled: false,
  }),
  computed: {
    canPrint() {
      return this.orcamento.status != "C" && !this.valida;
    },
    canSend() {
      return (
        !this.hasProductChanges &&
        this.orcamentoItens.length == this.productChangesReturned
      );
    },
    canEdit() {
      return this.orcamento.status == "D" || this.orcamento.status == "S";
    },
    isEditing() {
      return this.orcamento.status == "D";
    },
    valorTotalSum() {
      return this.orcamentoItens.reduce(function (total, item) {
        return total + item.valor_total * 1;
      }, 0);
    },
    comissaoSum() {
      return this.orcamentoItens.reduce(function (total, item) {
        return total + item.valor_comissao;
      }, 0);
    },
    clientStatus() {
      return this.clientBasicInfo.status;
    },
    orcamentoStatus() {
      return this.orcamento.status;
    },
    formaPagamentoEditing() {
      if (this.orcamento.status == "D" || this.orcamento.status == "C") {
        return true;
      }
    },
    exibirPagamentoSelecionado() {
      if (this.orcamento.forma_pgto != "") {
        return this.orcamento.forma_pgto;
      }
    },
  },
  beforeMount() {
    this.validaPermissao();
    this.orcamento_id = this.$route.params.id;
    this.loadBudget(this.orcamento_id);

    EventBus.$on("onBudgetItemHasChanges", (item) => {
      this.productChangesReturned++;
      if (item.changed) this.hasProductChanges = true;
    });
  },
  methods: {
    validaPermissao() {
      GrupoPermissao.getPermissao("/vendas/orcamentos").then((response) => {
        if (!response.data.data) this.$router.push("/");
        else this.disabled = response.data.data.edita != "1";
      });
    },
    loadBudget(id) {
      this.orcamento = new Object();
      Orcamento.getByCodigo(id)
        .then((response) => {
          this.orcamento = response.data.data;
          this.filial = this.orcamento.cd_filial;
          this.loadClientBasicInfo(this.orcamento.cliente_codigo);
          this.loadOrcamentoItens(this.orcamento.id);
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
    loadOrcamentoItens(orcamento_id) {
      if (orcamento_id == null || orcamento_id == 0) return;
      this.orcamentoItens = [];
      this.productChangesReturned = 0;
      Orcamento.getOrcamentoItens(orcamento_id)
        .then((response) => {
          this.orcamentoItens = response.data.data;

          this.validaBot(this.orcamentoItens);
        })
        .catch((e) => {
          if (e.response.status === 404) return;
          this.$helper.showErrorResponse(e);
        });
    },
    validaBot(value) {
      for (let i = 0; i < value.length; i++) {
        if (value[i].vl_liquido_tab_coorp != 0) {
          this.valida = true;
        } else {
          this.show = true;
        }
      }
    },
    loadClientBasicInfo(cliente_codigo) {
      if (cliente_codigo == null || cliente_codigo == 0) return;
      this.clientBasicInfo = new Object();
      Cliente.getBasicInfo(cliente_codigo)
        .then((response) => {
          this.clientBasicInfo = response.data.data;
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
    onSortChanged() {},
    confirmSend() {
      if (this.clientBasicInfo.status != "A") {
        this.$helper.showMsg(
          "Para enviar o orçamento o Status do cliente deve ser Ativo.",
          "warning"
        );
        return;
      }

      if (
        this.orcamento.data_validade == null ||
        this.orcamento.data_validade.toString().trim() == ""
      ) {
        this.$helper.showMsg(
          "Para enviar o orçamento informe a Data de Validade.",
          "warning"
        );
        return;
      }

      this.$bvModal
        .msgBoxConfirm(
          "Tem certeza que deseja enviar Orçamento Nº. " +
            this.orcamento.id +
            ", depois de enviado não será mais possível alterá-lo?",
          {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okVariant: "success",
            okTitle: "Sim",
            cancelTitle: "Não",
            cancelVariant: "danger",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            if (this.orcamento.status == "D") {
              var self = this;
              this.saveOrcamento(function () {
                self.sendOrcamento();
              });
            } else {
              this.sendOrcamento();
            }
          }
        })
        .catch((err) => {
          this.$helper.showErrorResponse(err);
        });
    },
    getFilial() {
      return this.filial == 1 ? "Maringá - PR" : "Itajaí - SC";
    },
    saveOrcamento(executeIfSucess = null) {
      Orcamento.saveOrcamento(this.orcamento)
        .then((response) => {
          this.$helper.showMsg(response.data.msg, response.data.type);
          if (executeIfSucess == null) {
            this.$router.push("/vendas/orcamentos");
          } else {
            this.Orcamento.status = "S";
            executeIfSucess();
          }
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
    sendOrcamento() {
      Orcamento.sendOrcamento(this.orcamento.id)
        .then((response) => {
          this.$helper.showMsg(response.data.msg, response.data.type);
          this.$router.push("/vendas/orcamentos");
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
    printOrcamento() {
      Orcamento.printOrcamento(this.orcamento.id)
        .then((response) => {
          let fileURL = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/pdf",
            })
          );
          let windowPrint = window.open(fileURL, "about:blank");
          windowPrint.print();
        })
        .catch((e) => {
          debugger;
          this.$helper.showErrorResponse(e);
        });
    },
    duplicarOrcamento() {
      Orcamento.duplicarOrcamento(this.orcamento)
        .then((response) => {
          this.$helper.showMsg(response.data.msg, response.data.type);
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
    editOrcamento() {
      this.$router.push(
        "/vendas/orcamentos/orcamento/" + this.orcamento.id + "/editar"
      );
    },
  },
};
</script>
